<div class="dialog-stretch">
  <!-- HEADER -->
  <div class="d-flex justify-content-between pt-2"
    mat-dialog-title>
    <h2 class="mb-0 mat-headline-6  w-100 d-flex align-items-center">{{'SALE.SELECT_QUANTITY' | translate}}
    </h2>
    <button mat-icon-button
      (click)="onClose()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <mat-dialog-content class="py-2">
    <div *ngFor="let item of items"
      class="d-flex align-items-center mb-3">
      <div class="flex-1">
        <b>{{ item.inventory }}</b>
        <div>{{ item.description}} (MAX: {{item.quantity}})</div>
      </div>
      <mat-form-field appearance="outline"
        class="form-field-condensed grab-weight pr-2">
        <mat-label>{{'SALE.GRAB_WEIGHT' | translate}}</mat-label>
        <input matInput
          [(ngModel)]="item.selectedGrabWeight"
          min="1"
          type="number">
      </mat-form-field>
      <mat-form-field appearance="outline"
        class="form-field-condensed">
        <mat-label>{{'SALE.QUANTITY' | translate}}</mat-label>
        <input matInput
          [(ngModel)]="item.selectedQuantity"
          min="1"
          [max]="item.quantity"
          type="number">
      </mat-form-field>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="end"
    class="d-flex align-items-center">
    <!-- ACTIONS -->
    <button mat-flat-button
      (click)="onClose()">
      {{ 'BITF.DIALOGS.BUTTON_CANCEL_TEXT' | translate }}
    </button>
    <button mat-flat-button
      color="primary"
      (click)="onSave()">
      {{ 'BITF.DIALOGS.BUTTON_OK_TEXT_TO_SAVE' | translate }}
    </button>
  </mat-dialog-actions>
</div>