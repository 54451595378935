import { Injectable, Injector } from '@angular/core';
import { Observable, from } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { gql } from 'apollo-angular';

import { BitfGraphQlService } from '@bitf/services/graph-ql/bitf-graph-ql.service';

import { IBitfGraphQlRequest, IBitfGraphQlResponse } from '@interfaces';
import { Archive, Image } from '@models';
import {
  getArchivePrintDataGql,
  getArchiveWithAllRelationsEntityGql,
  getArchiveGalleryGql,
  getArchiveStatsGql,
} from './archives.gql';
import { PAGINATION_ENTITY } from './strapi.gql';
import { BitfFile } from '@common/libs/bitforce/core/models';
import { FilesService } from './files.service';

@Injectable({
  providedIn: 'root',
})
// NOTE: Add methods following CRUD order
export class ArchivesService extends BitfGraphQlService {
  constructor(public injector: Injector, private filesService: FilesService) {
    super(injector);
  }

  createItem(requestParams: IBitfGraphQlRequest = {}) {
    requestParams.modelMapper = 'createArchive';
    requestParams.mutation = gql`
      ${getArchiveWithAllRelationsEntityGql()}
      mutation Create($input: ArchiveInput!) {
        createArchive(data: $input) {
          data {
            ...ArchiveWithAllRelationsEntity
          }
        }
      }
    `;

    return super.mutate<Archive>(requestParams);
  }

  getAllIds(requestParams: IBitfGraphQlRequest = {}): Observable<IBitfGraphQlResponse<Archive[]>> {
    requestParams.modelMapper = 'archives';
    requestParams.query = gql`
      ${PAGINATION_ENTITY}
      query Query($pagination: PaginationArg, $filters: ArchiveFiltersInput) {
        archives(pagination: $pagination, filters: $filters) {
          data {
            id
          }
          meta {
            pagination {
              ...PaginationEntity
            }
          }
        }
      }
    `;
    return super.query<Archive[]>(requestParams);
  }

  getArchivesStats(requestParams: IBitfGraphQlRequest = {}) {
    requestParams.query = gql`
      ${getArchiveStatsGql()}
    `;
    // set size and page to null in order to disable pagination response parser part.
    return super.query<{
      totalArchivesValue: number;
      totalArchivesEstimate: number;
      totalArchivesQuantity: number;
      totalArchivesNPieces: number;
    }>({
      ...requestParams,
      modelMapper: 'action',
      size: null,
      page: null,
    });
  }

  getNeighbours(requestParams: IBitfGraphQlRequest = {}) {
    requestParams.query = gql`
      ${getArchiveWithAllRelationsEntityGql()}
      query Query($filters: ArchiveFiltersInput, $sort: [String]) {
        neighbours(sort: $sort, filters: $filters) {
          ...ArchiveWithAllRelationsEntity
        }
      }
    `;
    // set size and page to null in order to disable pagination response parser part.
    return super
      .query<{ neighbours: Archive[] }>({
        ...requestParams,
        modelMapper: 'action',
        size: null,
        page: null,
      })
      .pipe(
        map(response => ({
          ...response,
          content: response.content.neighbours.map(neighbour => new Archive(neighbour)),
        }))
      );
  }

  getItems(
    requestParams: IBitfGraphQlRequest = {},
    queryType: 'fullItems' | 'gallery' = 'gallery'
  ): Observable<IBitfGraphQlResponse<Archive[]>> {
    requestParams.modelMapper = 'archives';
    switch (queryType) {
      case 'fullItems':
        requestParams.query = gql`
          ${getArchiveWithAllRelationsEntityGql()}
          ${PAGINATION_ENTITY}
          query Query($pagination: PaginationArg, $sort: [String], $filters: ArchiveFiltersInput) {
            archives(pagination: $pagination, sort: $sort, filters: $filters) {
              data {
                ...ArchiveWithAllRelationsEntity
              }
              meta {
                pagination {
                  ...PaginationEntity
                }
              }
            }
          }
        `;
        break;
      case 'gallery':
        requestParams.query = gql`
          ${getArchiveGalleryGql()}
        `;
        break;
    }

    return super.query<Archive[]>(requestParams);
  }

  getPrintData(requestParams: IBitfGraphQlRequest = {}): Observable<IBitfGraphQlResponse<Archive[]>> {
    requestParams.modelMapper = 'archives';
    requestParams.query = gql`
      ${getArchivePrintDataGql()}
    `;

    return super.query<Archive[]>(requestParams);
  }

  getItemById(requestParams: IBitfGraphQlRequest): Observable<IBitfGraphQlResponse<Archive>> {
    requestParams.modelMapper = 'archive';
    requestParams.query = gql`
      ${getArchiveWithAllRelationsEntityGql()}
      query Query($id: ID) {
        archive(id: $id) {
          data {
            ...ArchiveWithAllRelationsEntity
          }
        }
      }
    `;

    return super.query<Archive>(requestParams);
  }

  getItem(requestParams: IBitfGraphQlRequest = {}): Observable<IBitfGraphQlResponse<Archive>> {
    requestParams.modelMapper = 'archives';
    requestParams.query = gql`
      ${getArchiveWithAllRelationsEntityGql()}
      query Query($pagination: PaginationArg, $filters: ArchiveFiltersInput) {
        archives(pagination: $pagination, filters: $filters) {
          data {
            ...ArchiveWithAllRelationsEntity
          }
        }
      }
    `;

    return super.query<Archive>(requestParams).pipe(
      map(response => ({
        ...response,
        content: response?.content[0] || undefined,
      }))
    );
  }

  updateItem(
    requestParams: IBitfGraphQlRequest<Partial<Archive>> = {},
    archive?: Partial<Archive>
  ): Observable<IBitfGraphQlResponse<Archive>> {
    requestParams.modelMapper = 'updateArchive';
    requestParams.mutation = gql`
      ${getArchiveWithAllRelationsEntityGql()}
      mutation Update($id: ID!, $input: ArchiveInput!) {
        updateArchive(id: $id, data: $input) {
          data {
            ...ArchiveWithAllRelationsEntity
          }
        }
      }
    `;
    return super.mutate<Archive>(requestParams, archive).pipe(
      tap((response: IBitfGraphQlResponse<Archive>) => {
        if (archive) {
          Object.assign(archive, response.content);
        }
      })
    );
  }

  deleteItem(requestParams: IBitfGraphQlRequest = {}) {
    requestParams.modelMapper = 'deleteArchive';
    requestParams.mutation = gql`
      mutation Mutate($id: ID!) {
        deleteArchive(id: $id) {
          data {
            id
          }
        }
      }
    `;
    return super.mutate<Archive>(requestParams);
  }

  duplicateArchive(archive: Archive, duplicateInventory = false) {
    const observableList = archive.images.map(image =>
      this.getImageFile(image).pipe(
        switchMap(imageFile =>
          this.filesService.createImage({
            variables: { file: new BitfFile(imageFile) },
            context: { useMultipart: true },
            disableHideLoader: true,
          })
        )
      )
    );

    const newArchive = new Archive(undefined, {
      ...archive,
      archive_loans: [],
      archive_sales: [],
      folders: [],
      images: [],
      id: undefined,
    });

    return this.createItem({
      body: {
        ...newArchive.serialised,
        inventory: duplicateInventory ? archive.inventory : undefined,
      },
      disableHideLoader: true,
    }).pipe(
      observableList.length > 0
        ? switchMap((newArchiveResponse: IBitfGraphQlResponse<Archive>) =>
            this.filesService.getThrottledObservables(observableList).pipe(
              tap((responses: IBitfGraphQlResponse<Image>[]) => {
                // NOTE: set the ids of images uploaded to the new archive
                newArchiveResponse.content.images = responses.map(res => res.content);
              }),
              catchError(error => {
                console.log(error);
                return error;
              }),
              switchMap(() =>
                this.updateItem({
                  body: newArchiveResponse.content,
                  disableHideLoader: true,
                })
              )
            )
          )
        : tap(() => {})
    );
  }

  private getImageFile(image: Image): Observable<File> {
    return from(
      new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.onload = function () {
          const reader = new FileReader();
          reader.onloadend = () => {
            resolve(reader.result);
          };
          reader.readAsDataURL(xhr.response);
        };
        xhr.onerror = reject;
        xhr.open('GET', image.url);
        xhr.responseType = 'blob';
        xhr.send();
      })
    ).pipe(
      switchMap((base64: any) =>
        from(
          fetch(base64)
            .then(res => res.blob())
            .then(
              blob =>
                new File([blob], `copy-${image.name}`, {
                  type: image.formats?.thumbnail?.mime || 'image/jpeg',
                })
            )
        )
      )
    );
  }
}
